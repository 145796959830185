<template>
    <div>
        <div class="card events-card mb-3">
            <div class="is-flex is-justify-content-space-between px-2  is-color-palete-blue-1 ">
                <div>
                    <p class="card-header-title is-size-6 pl-1 py-1 has-text-white"> {{$t("loading_today") }}</p>
                </div>
                <div class="is-flex is-align-items-center is-justify-content-flex-end ">
                </div>
            </div>
            <div class="card-table">
                <div class="content trucks-table-height custom_scrollbar-1">
                    <table v-if="get_dashboard_loading_trucks.length > 0" class="table is-striped dashboard-table is-narrow is-fullwidth is-size-7">
                        <thead class="th-border-blue-1">
                            <th>{{$t("truckname")}}</th>
                            <th>{{$t("project_name")}}</th>
                            <th>{{$t("loading_schedule")}} </th>
                        </thead>
                        <tbody>
                            <tr v-for="(nf, index) in get_dashboard_loading_trucks" :key="index" class="th-border-blue-1">
                                <td>{{nf.truck_name}}</td>
                                <td>{{nf.name}}</td> 
                                <td>{{nf.loading_schedule | customdate}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <small v-else class="ml-2 has-text-grey-light" >
                        No data
                    </small>
                </div>
            </div>
            <!-- <footer v-if="get_dashboard_loading_trucks.length && this.$parent.modules_data.my_loading_trucks.filter(t => t.event_type == 'loading-deliver').length != get_dashboard_loading_trucks.length"
                    class="card-footer p-1 ">
                <a @click.prevent="toggle_limit()" href="#" class="card-footer-item p-0 is-small has-text-white">{{$t("load_more")}}</a>
            </footer> -->
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            limit: 6,
            default_limit: 6
        }
    },
    computed: {
        get_dashboard_loading_trucks(){ 
            return this.$parent.modules_data.my_loading_trucks.filter(t => t.event_type == 'loading-deliver').filter(a => {
                if(this.$parent.selected_project_id == a.project_id){
                    console.table(this.$parent.selected_project_id, a.project_id)
                    return a
                }
                else if(!this.$parent.selected_project_id) return a
            }) 
            .filter((obj) =>{
                if(this.$parent.tetris_search_text != ''){
                    return JSON.stringify(obj).toLowerCase().includes(this.$parent.tetris_search_text.toLowerCase())
                } else return obj
            })
        },
    },
    methods: {
        toggle_limit(){
            this.limit =  this.limit + this.default_limit
        },
    }

}
</script>

<style>
</style>